import { React } from "react";
import BackToTop from "./components/atoms/BackToTop";
import About from "./components/organisms/About";
import Banner from "./components/organisms/Banner";
import ContactSection from "./components/organisms/ContactSection";
import Footer from "./components/organisms/Footer";
import Header from "./components/organisms/Header";
import Integrations from "./components/organisms/Integrations";
import Services from "./components/organisms/Services";
import { ColorModeContextProvider } from "./contexts/Theme";

function App() {
  return (
    <ColorModeContextProvider>
      {/* HEADER */}
      <Header />

      {/* SECTIONS */}
      <Banner />
      <About />
      <Services />
      <Integrations />
      <ContactSection />

      {/* FOOTER */}
      <Footer />
      <BackToTop />
    </ColorModeContextProvider>
  );
}

export default App;
