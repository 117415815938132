import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { LanguageContext } from "../../contexts/Language";

function About() {
  // Contexts
  const [language] = useContext(LanguageContext);

  // Constants
  const features = [
    {
      title: language.multiCurrency,
      description: language.companyInfo.content.sub1,
      icon: <CurrencyExchangeIcon />,
    },
    {
      title: language.digitalCards,
      description: language.companyInfo.content.sub2,
      icon: <CardGiftcardIcon />,
    },
    {
      title: language.integrations,
      description: language.companyInfo.content.sub3,
      icon: <PointOfSaleIcon />,
    },
  ];

  // Render
  return (
    <section className="pt-10 min-h-[500px] dark:bg-deepDarkBlue dark:text-white text-tcsBlueDark bg-primary" id="about">
      <div className="container flex items-center">
        <div className="block py-10 mx-auto">
          {/* TITLE */}
          <h2 className="p-5 text-3xl text-center">{language.companyInfo.title}</h2>

          {/* CONTENT */}
          <div className="p-5 mb-8 text-lg text-center dark:text-textLightGrey text-ligthGrey">{language.companyInfo.content.main}</div>

          {/* FEATURES */}
          <Grid container spacing={8}>
            {features.map((feature) => (
              <Grid item xs={12} md={4} key={feature.title}>
                <div
                  className="px-4 py-10 mx-auto w-full h-full rounded-xl dark:bg-mediumBlue dark:shadow-none dark-ring"
                  key={feature.title}
                >
                  <div className="flex items-center dark:text-white text-tcsBlueDark">
                    {/* FEATURE ICON */}
                    <div className="p-1">{feature.icon}</div>

                    {/* FEATURE TITLE */}
                    <h3 className="p-6 px-4 text-2xl capitalize dark:text-white text-tcsBlueDark">{feature.title}</h3>
                  </div>

                  {/* FEATURE CONTENT */}
                  <p className="text-ligthGrey">{feature.description}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </section>
  );
}

export default About;
