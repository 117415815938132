import German from "../i18n/de.json";
import English from "../i18n/en.json";
import French from "../i18n/fr.json";
import Dutch from "../i18n/nl.json";

import { getLanguageCodeFromStorage } from "./storage";

const getLanguage = (languageCode) => {
  // If languageCode is undefined then first attempt to read the languageCode from local
  // storage, otherwise use the browser's locale to set the languageCode.
  if (!languageCode) {
    languageCode = getLanguageCodeFromStorage();
  }

  if (languageCode === "DE") return German;
  if (languageCode === "FR") return French;
  if (languageCode === "EN") return English;

  // Default to Dutch if language cannot be matched above.
  return Dutch;
};

export default getLanguage;
