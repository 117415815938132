const languageCode = "languageCode";

export const getFromStore = (key) => {
  switch (process.env.REACT_APP_PLATFORM) {

    case "web":
      if (typeof window !== "undefined" && window.localStorage)
        return window.localStorage.getItem(key);
      break;  
    default:
      if (typeof window !== "undefined" && window.localStorage)
        return window.localStorage.getItem(key);
      break;
  }
};

export const setToStore = (key, value) => {

  switch (process.env.REACT_APP_PLATFORM) {
    case "web":
      if(typeof window !== 'undefined' && window.localStorage) window.localStorage.setItem(key, value);
      break;
    default:
      if(typeof window !== 'undefined' && window.localStorage) window.localStorage.setItem(key, value);
      break;
  }
}
export const getLanguageCodeFromStorage = () => {
  return getFromStore(languageCode);
};

export const setLanguageCodeToStorage = (value) => {
  setToStore(languageCode, value);
};
