import React from "react";

function Logo(props) {
  return (
    <a
      key={props.index}
      href={props.logo.href}
      target="_blank"
      rel="noopener noreferrer"
      className="flex object-contain justify-center items-center my-auto w-full h-full dark:bg-white"
    >
      <img
        src={props.logo.src}
        alt={props.logo.alt}
        className="max-h-16"
      />
    </a>
  );
}

export default Logo;
