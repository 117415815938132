import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/Language";
import { importFiles } from "../../utils/utils";
import Logos from "../molecules/Logos";

function DistributionsLogos() {
  const [language] = useContext(LanguageContext);
  const images = importFiles(require.context("../../assets/images/integrations/distributions/", false, /\.(webp|gif|png|jpe?g|svg)$/));

  const logos = [
    {
      alt: "ANWB",
      src: images["anwb.svg"],
      href: "https://www.anwb.nl/",
    },
    {
      alt: "Blackhawk",
      src: images["blackhawk.svg"],
      href: "https://blackhawknetwork.com/",
    },
    {
      alt: "Primera",
      src: images["primera.svg"],
      href: "https://www.primera.nl/",
    },
    {
      alt: "Lekkerland",
      src: images["lekkerland.svg"],
      href: "https://www.lekkerland.nl/",
    },
    {
      alt: "Conway",
      src: images["conway.svg"],
      href: "https://www.conway.be/",
    },
    {
      alt: "epay",
      src: images["epay.svg"],
      href: "https://epay.de/",
    },
    {
      alt: "ICP",
      src: images["icp.jpg"],
      href: "https://www.icpcompanies.nl/",
    },
    {
      alt: "Dappre",
      src: images["dappre.svg"],
      href: "https://dappre.com/",
    },
    {
      alt: "GivaCard",
      src: images["givacard.svg"],
      href: "https://www.givacard.nl/",
    },
    {
      alt: "giftcards.group",
      src: images["giftcards-group.svg"],
      href: "https://www.giftcardsgroup.com/",
    },
    {
      alt: "Tixway",
      src: images["tixway.png"],
    },
    {
      alt: "H@nd",
      src: images["hand.svg"],
      href: "https://www.hand-ebs.com/",
    },
  ];

  return <Logos title={language.distribution} logos={logos} border />;
}

export default DistributionsLogos;
