import React, { useState } from "react";
import getLanguage from "../utils/languages";

const LanguageContext = React.createContext([{}, () => {}]);
function LanguageContextProvider(props) {
  const [state, setState] = useState(getLanguage());

  return <LanguageContext.Provider value={[state, setState]}>{props.children}</LanguageContext.Provider>;
}

export { LanguageContext, LanguageContextProvider };
