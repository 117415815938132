import React, { useContext } from "react";
import { importFiles } from "../../utils/utils";
import { LanguageContext } from "../../contexts/Language";
import Logos from "../molecules/Logos";

function ElectronicMoneyInstitutionLogos() {
  const [language] = useContext(LanguageContext);
  const images = importFiles(
    require.context("../../assets/images/integrations/electronic-money-institutions/", false, /\.(webp|gif|png|jpe?g|svg)$/),
  );

  const logos = [
    {
      alt: "ingenico",
      src: images["ingenico.svg"],
      href: "https://www.ingenico.com/",
    },
  ];

  return <Logos title={language.egi} logos={logos} border />;
}

export default ElectronicMoneyInstitutionLogos;
