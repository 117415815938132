import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Zoom from "@mui/material/Zoom";
import React from "react";

const CustomizedFab = styled(Fab)`
  &.MuiButtonBase-root {
      background-color: 	#23446A;
      color:white
    },
`;

function ScrollTop(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector("#banner");

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: "fixed", bottom: 32, right: 32 }}>
        {children}
      </Box>
    </Zoom>
  );
}

function BackToTop() {
  return (
    <ScrollTop>
      <CustomizedFab size="small" className="bg-secondary">
        <KeyboardArrowUpIcon />
      </CustomizedFab>
    </ScrollTop>
  );
}

export default BackToTop;
