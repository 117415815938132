import React, { useContext } from "react";
import { importFiles } from "../../utils/utils";
import { LanguageContext } from "../../contexts/Language";
import Logos from "../molecules/Logos";

function PaymentServiceProviderLogos() {
  const [language] = useContext(LanguageContext);
  const images = importFiles(
    require.context("../../assets/images/integrations/payment-service-providers/", false, /\.(webp|gif|png|jpe?g|svg)$/),
  );

  const logos = [
    {
      alt: "Adyen",
      src: images["adyen.svg"],
      href: "https://www.adyen.com/",
    },
    {
      alt: "WooCommerce",
      src: images["woo-commerce.svg"],
      href: "https://woocommerce.com/",
    },
    {
      alt: "MultiSafepay",
      src: images["multisafepay.svg"],
      href: "https://www.multisafepay.com/",
    },
    {
      alt: "PAY",
      src: images["pay.svg"],
      href: "https://www.pay.nl/",
    },
    {
      alt: "Buckaroo",
      src: images["buckaroo.svg"],
      href: "https://www.buckaroo.nl/",
    },
    {
      alt: "CM",
      src: images["cm.svg"],
      href: "https://www.cm.com/",
    },
    {
      alt: "ICEPAY",
      src: images["icepay.svg"],
      href: "https://icepay.nl/",
    },
    {
      alt: "Valk Solutions",
      src: images["valk-solutions.png"],
      href: "https://valksolutions.nl/",
    },
    {
      alt: "emerchantpay",
      src: images["emerchantpay.png"],
      href: "https://www.emerchantpay.com/",
    },
    {
      alt: "Ingenico",
      src: images["ingenico.svg"],
      href: "https://www.ingenico.com/",
    },
  ];

  return <Logos title={language.psp} logos={logos} border />;
}

export default PaymentServiceProviderLogos;
