import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

// https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      main: "#27486c",
    },
    secondary: {
      main: "#477D9A",
    },
    // error: {
    //   main: "#bf616a",
    // },
    // warning: {
    //   main: "#ebcb8b",
    // },
    info: {
      main: "#81a1c1",
    },
    // success: {
    //   main: "#a3be8c",
    // },
  },
});

const getColorMode = () => {
  if (typeof window !== "undefined" && window.localStorage) window.localStorage.getItem("colorMode");
};

const setColorMode = (mode) => {
  if (typeof window !== "undefined" && window.localStorage) window.localStorage.setItem("colorMode", mode);
};

function ColorModeContextProvider(props) {
  const { children } = props;

  // States
  const [mode, setMode] = useState(getColorMode() ? getColorMode() : "light");

  // Memos
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousMode) => (previousMode === "light" ? "dark" : "light"));
      },
    }),
    [],
  );

  // Effects
  useEffect(() => {
    setColorMode(mode);
  }, [mode]);

  // Render
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

ColorModeContextProvider.propTypes = {
  children: PropTypes.element,
};

ColorModeContextProvider.defaultProps = {
  children: <div />,
};

export { ColorModeContext, ColorModeContextProvider };
