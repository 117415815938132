import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/Language";

function MeetingBooker(props) {
  const { open, onClose } = props;

  // Contexts
  const [language] = useContext(LanguageContext);

  // Render
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      {/* TITLE */}
      <DialogTitle sx={{ textAlign: "center", color: "#315A88" }}>{language.bookAMeeting}</DialogTitle>

      {/* CLOSE BUTTON */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* CONTENT */}
      <DialogContent sx={{ bgcolor: "#f4f4f4", textAlign: "center" }}>
        <iframe
          src="https://outlook.office365.com/owa/calendar/Gefd05798919a4be79ec752379d56a777@123tcs.com/bookings/"
          title="Booking"
          width="100%"
          height="100%"
        />
      </DialogContent>
    </Dialog>
  );
}

MeetingBooker.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

MeetingBooker.defaultProps = {
  open: false,
};

export default MeetingBooker;
