import React, { useContext } from "react";
import { importFiles } from "../../utils/utils";
import { LanguageContext } from "../../contexts/Language";
import Logos from "../molecules/Logos";
import CCVLogo from "../../assets/images/integrations/pins/ccv.svg";

function PinLogos() {
  const [language] = useContext(LanguageContext);

  const images = importFiles(require.context("../../assets/images/integrations/pins/", false, /\.(webp|gif|png|jpe?g|svg)$/));

  const logos = [
    {
      alt: "CCV",
      src: CCVLogo,
      href: "https://www.ccv.eu/nl/",
    },
    {
      alt: "Wordline",
      src: images["worldline.png"],
      href: "https://nl.worldline.com/nl/home.html",
    },
  ];

  return <Logos title={language.pin} logos={logos} border />;
}

export default PinLogos;
