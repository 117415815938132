import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useContext, useState } from "react";
import { LanguageContext } from "../../contexts/Language";
import getLanguage from "../../utils/languages";
import { setLanguageCodeToStorage } from "../../utils/storage";

function ChangeLanguage() {
  const [, setLanguage] = useContext(LanguageContext);
  const [languageCode, setLanguageCode] = useState(
    window.localStorage.getItem("languageCode") ? window.localStorage.getItem("languageCode") : "NL",
  );

  const handleChangeLanguage = (event) => {
    try {
      setLanguageCode(event.target.value);
      setLanguageCodeToStorage(event.target.value);
      setLanguage(getLanguage(event.target.value));
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <FormControl variant="standard">
      <Select
        variant="standard"
        value={languageCode}
        onChange={handleChangeLanguage}
        sx={{ color: "white" }}
        inputProps={{ MenuProps: { disableScrollLock: true } }}
      >
        <MenuItem value="NL">🇳🇱 Nederlands</MenuItem>
        <MenuItem value="EN">🇬🇧 English</MenuItem>
        <MenuItem value="DE">🇩🇪 Deutsch</MenuItem>
        <MenuItem value="FR">🇫🇷 Français</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ChangeLanguage;
