export const importFiles = (path) => {
  let images = {};

  path.keys().map((item) => (images[item.replace("./", "")] = path(item)));

  return images;
};

export const verifyIpAddress = async () => {
  console.log("FOO");
  let response = await fetch("https://geolocation-db.com/json/", {
    method: "GET",
  });
  console.log("BAR");
  const responseBody = await response.json();
  console.log("FOOBAR");
  const ipAddress = responseBody.IPv4;

  console.log("IPADDR", ipAddress);

  // const redisConnection = getRedisConnection();
};

// function getRedisConnection() {
//   const redis = require("ioredis");
//   const host = {
//     port: process.env.REDIS_PORT,
//     host: process.env.REDIS_URL,
//     password: process.env.REDIS_PSW
//   };

//   return new redis(host);
// }
