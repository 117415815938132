import { Grid } from "@mui/material";
import React, { useContext } from "react";
import PrivacyStatement from "../../assets/content/privacy-statement-tcs-eng-nl.d6f5a5.pdf";
import CompanyLogo from "../../assets/images/logo123tcs.png";
import { LanguageContext } from "../../contexts/Language";

function Footer() {
  // Contexts
  const [language] = useContext(LanguageContext);

  // Render
  return (
    <footer className="border-t dark:border-none">
      <div
        className="md:px-4 md:pt-4 md:pb-5 dark-ring md:border-none dark:bg-deepDarkBlue dark:text-white text-tcsBlueDark bg-primary }"
        id="footer"
      >
        <div className="container">
          <div className="flex mx-auto max-w-screen-lg">
            <Grid container className="text-center text-white md:text-left">
              {/* ADDRESS */}
              <Grid item xs={12} md={4} className="p-8">
                <h3 className="block text-xs font-bold tracking-wider text-pink-700 uppercase">{language.address}</h3>

                <div className="flex flex-col py-4 text-tcsBlueDark dark:text-textLightGrey">
                  <p className="py-2 text-sm tracing-wide">Joris Dircksenstraat 18</p>
                  <p className="py-2 text-sm tracing-wide"> 3621 CB Breukelen </p>
                  <p className="py-2 text-sm tracing-wide">Nederland</p>
                </div>
              </Grid>

              {/* CONTACT */}
              <Grid item xs={12} md={4} className="p-8 md:border-l dark:border-none">
                <h3 className="block text-xs font-bold tracking-wider text-pink-700 uppercase">{language.contact}</h3>

                <div className="flex flex-col py-4 text-tcsBlueDark dark:text-textLightGrey">
                  <ul>
                    <li className="py-2 text-sm tracing-wide">
                      {language.emailAddress}:{" "}
                      <a href="mailto:info@123tcs.com" className="px-0 text-sm font-normal underline">
                        info@123tcs.com
                      </a>
                    </li>
                    <li className="py-2 text-sm tracing-wide">
                      {language.phoneNumber}:{" "}
                      <a href="tel:0031857441035" className="px-0 text-sm font-normal underline">
                        +31 (0)85 744 10 35
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>

              {/* OTHER */}
              <Grid item xs={12} md={4} className="p-8 md:border-l dark:border-none">
                <h3 className="block text-xs font-bold tracking-wider text-pink-700 uppercase">{language.others}</h3>

                <div className="flex flex-col py-4 text-tcsBlueDark dark:text-textLightGrey">
                  <ul>
                    <li className="py-2 text-sm tracing-wide">BTW nr: NL8192.44.041.B01</li>
                    <li className="py-2 text-sm tracing-wide">KvK nr: 34298477</li>
                    <li className="py-2 text-sm tracing-wide">
                      <a href={PrivacyStatement} target="_blank" rel="noopener noreferrer" className="px-0 text-sm font-normal underline">
                        Privacy Statement
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="flex flex-col mx-auto mt-8 max-w-screen-lg">
            <img src={CompanyLogo} alt="123TCS Logo" width="120px" className="mx-auto" />

            <p className="mx-auto my-4 text-sm text-white dark:text-textLightGrey">
              {"Copyright © "}
              <a href="/" className="text-base font-normal underline">
                123TCS
              </a>
              {` ${new Date().getFullYear()}.`}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
