import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useContext } from "react";
import ServicesImg from "../../assets/images/service-bg.png";
import { LanguageContext } from "../../contexts/Language";

function Services() {
  // Contexts
  const [language] = useContext(LanguageContext);

  // Render
  return (
    <section className={`flex justify-center pt-10 text-white shadow min-h-[700px] dark:bg-darkBlue ${"bg-secondary"}`} id="services">
      <div className="container flex items-center">
        <Grid container spacing={2} columns={12}>
          {/* CONTENT */}
          <Grid item xs={12} md={6}>
            <Container className="flex-1 px-3 py-4 mx-auto rounded-lg bg-secondary dark:bg-darkBlue">
              <h2 className="px-5 py-3 text-2xl leading-10 uppercase text-bold">{language.service.title}</h2>
              <div className="px-5 py-3 text-lg text-textLightGrey">{language.service.content}</div>
            </Container>
          </Grid>

          {/* IMG */}
          <Grid item xs={12} md={6} container justifyContent="center">
            <img src={ServicesImg} alt="Service" className="object-contain" />
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default Services;
