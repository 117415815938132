import { List, ListItem } from "@mui/material";
import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/Language";
import CashRegisterLogos from "../atoms/CashRegisters";
import DistributionsLogos from "../atoms/Distributions";
import ElectronicMoneyInstitutionLogos from "../atoms/ElectronicMoneyInstitution";
import GiftLogos from "../atoms/Gift";
import PaymentServiceProviderLogos from "../atoms/PaymentServiceProvider";
import PinLogos from "../atoms/Pin";

function Integrations() {
  // Context
  const [language] = useContext(LanguageContext);

  // Render
  return (
    <section
      className="flex justify-center pt-20 min-h-[500px] dark:bg-deepDarkBlue dark:text-white text-tcsBlueDark bg-primary"
      id="intergrations"
    >
      <div className="container block items-center md:px-4 md:pt-10 md:pb-5">
        <div className="block mx-auto">
          {/* TITLE */}
          <h1 className="p-3 text-3xl leading-10 text-center uppercase">{language.integration.title}</h1>

          {/* CONTENT */}
          <div className="text-lg text-center before:p-3 dark:text-textLightGrey text-tcsDarkBlue text-ligthGrey">
            {language.integration.content}
          </div>
        </div>

        {/* LOGOS */}
        <List sx={{ paddingTop: "20px" }}>
          <ListItem divider>
            <CashRegisterLogos />
          </ListItem>
          <ListItem divider>
            <DistributionsLogos />
          </ListItem>
          <ListItem divider>
            <GiftLogos />
          </ListItem>
          <ListItem divider>
            <PaymentServiceProviderLogos />
          </ListItem>
          <ListItem divider>
            <PinLogos />
          </ListItem>
          <ListItem>
            <ElectronicMoneyInstitutionLogos />
          </ListItem>
        </List>
      </div>
    </section>
  );
}

export default Integrations;
