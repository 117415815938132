import { Grid2 } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Logo from "../atoms/Logo";

function Logos(props) {
  const { logos, title } = props;

  // Render
  return (
    <div className="block mx-auto my-4 w-full text-black">
      <h3 className="p-3 font-bold leading-10 text-center uppercase text-ligthGrey">{title}</h3>

      <Grid2 container spacing={2} className="justify-center justify-items-center w-full">
        {logos.map((logo) => (
          <Grid2
            size={{ xs: 6, sm: 3, md: 2 }}
            className="flex justify-center justify-items-center backgroundColor"
            key={logo.alt}
            sx={{ minHeight: "64px" }}
          >
            <Logo logo={logo} index={logo.alt} />
          </Grid2>
        ))}
      </Grid2>
    </div>
  );
}

Logos.propTypes = {
  title: PropTypes.string,
  logos: PropTypes.arrayOf(),
};

Logos.defaultProps = {
  title: "",
  logos: [],
};

export default Logos;
